import React, { useState, useEffect } from 'react';
import { Col, Row, Input, Form, Divider, Button, Spin, Modal } from 'antd';
import NewTable from '../../../molecules/NewTable/NewTable';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import errorHandler from '../../../../utils/errorHandler';
import sendNotification from '../../../../utils/sendNotification';
import { toInteger } from 'lodash';

const EditLoadList = props => {
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [trfRecords, setTrfRecords] = useState([]);
  const [setValue, setStateValue] = useState([]);
  const [dataArray, setdataArray] = useState([]);
  const [offloadqty, setoffloadqty] = useState([]);
  const [_date, setDate] = useState("Date Here");
  const [isModalVisible, setisModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [count, setcount] = useState(0);
  const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100 });
  var data;
  const togglepopUp = props.handleClose;
  useEffect(() => {
    setStateValue(props.data);
    var _store = props.data.created_at;
    _store = _store.split(" ");
    var dateString = [];
    dateString.push(_store[1]);
    dateString.push(_store[2]);
    dateString.push(_store[3]);
    var _datestring = dateString[0] + " " + dateString[1] + " " + dateString[2];
    console.log(_datestring);
    setDate(_datestring);
    var _id = props.data;
    var _params = {
      "page": currentPage,
      "limit": currentLimit,
      "truck": props.data.truck
    }
    let data = '',
      apiUrl = `${API_URI}/admin/v1/loading_list`;

    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { params: _params, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          //;
          debugger;
          setTrfRecords(response.data.data.data);
          data = response.data.data.data;
          setFetching(false);
          setisModalVisible(true);
          //   debugger;
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        // sendNotification('Failed to fetch Txns', '', 'error');
      })
  }, [count]);
  useEffect(() => {
    const hasErrors = Object.values(errors).some((error) => error !== '');
    setIsSubmitDisabled(hasErrors);
  }, [errors]);

  const validateInput = (value, loadedQty) => {
    if (toInteger(value) > toInteger(loadedQty)) {
      return 'You cannot offload more than loaded QTY';
    }
    if (isNaN(value)) {
      return 'Please enter a valid number';
    }
    if (parseInt(value) < 0) {
      return 'Quantity cannot be negative';
    }
    return '';
  };


  const getData = (index, value, fullfilledQty, prevOffloadQty, pickedQty) => {
    fullfilledQty = toInteger(fullfilledQty)
    prevOffloadQty = toInteger(prevOffloadQty)
    pickedQty = toInteger(pickedQty)
    value = toInteger(value)
    var newOffloadQty = value;
    debugger
    const hasErrors = Object.values(errors).some((error) => error !== '');
    setIsSubmitDisabled(hasErrors);
    const errorMessage = validateInput(value, fullfilledQty);
    if (errorMessage) {
      setErrors((prev) => ({
        ...prev,
        [index]: errorMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [index]: '',
      }));
    }
    console.log("data and value are :", index, value)
    debugger
    fullfilledQty = toInteger(fullfilledQty) + toInteger(prevOffloadQty) - toInteger(newOffloadQty);
    debugger
    var missingQty = pickedQty - fullfilledQty
    var temp = { "id": index, "offloaded_qty": newOffloadQty, "loaded_qty": fullfilledQty, "missing_qty": missingQty };
    if (dataArray.length == 0) {
      dataArray.push(temp)
    }
    debugger;
    var flag = false;
    for (var i = 0; i < dataArray.length; i++) {
      if (dataArray[i].id == index) {
        dataArray[i].offloaded_qty = newOffloadQty;
        dataArray[i].loaded_qty = fullfilledQty;
        dataArray[i].missing_qty = missingQty;
        flag = true
        console.log(dataArray[i]);
        debugger
      }

    }
    if (!flag) {
      console.log(temp);
      dataArray.push(temp)
      debugger
    }
    // setdataArray(dataArray => [...dataArray, dataArray]);


  }
  const onSubmit = () => {
    console.log(dataArray);
    console.log(dataArray.length)
    debugger;
    let data = '',
      apiUrl = `${API_URI}/admin/v1/loading_list?truck=${props.data.truck}`;
    var _data = {
      "data": dataArray
    }
    debugger;
    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.put(apiUrl, _data, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);
        sendNotification("Details are Updated Successfully", "", "success")
        setcount(count + 1)
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        // sendNotification('Failed to fetch Txns', '', 'error');
      })

  }

  const columns = [
    {
      title: 'Supplier',
      dataIndex: 'SUPPLIER',
      key: 'SUPPLIER',
      width: '80px',
      align: 'left'
    },
    {
      title: 'Case Code',
      dataIndex: 'CASE CODE',
      key: 'CASE CODE',
      width: '100px',
    },
    {
      title: 'Sku Code',
      dataIndex: 'SKU CODE',
      key: 'sku_code',
      width: '100px',

    },

    {
      title: 'Case Quantity',
      dataIndex: 'CASE QTY',
      key: 'CASE QTY',
      width: '100px',
    },
    {
      title: 'Case Offloaded',
      dataIndex: 'OFFLOAD CASE QTY',
      key: 'OFFLOAD CASE QTY',
      width: '100px',
      render: (text, record) => {
        setoffloadqty(text)
        return (
          <>
            <Input defaultValue={text} required onChange={(event) => {
              getData(record.id, event.target.value, record['FLL CASE QTY'], record['OFFLOAD CASE QTY'], record['CASE QTY'])
              setoffloadqty(event.target.value); console.log(event.target.value)
            }} />
            {errors[record.id] && <div style={{ color: 'red' }}>{errors[record.id]}</div>}

          </>);
      }
    },
    {
      title: 'FLL CASE QTY',
      dataIndex: 'FLL CASE QTY',
      key: 'FLL CASE QTY',
      width: '100px',

    },
    {
      title: 'Sku Item Description',
      dataIndex: 'SKU ITEM DESCRIPTION',
      Key: 'SKU ITEM DESCRIPTION',
      width: '100px'

    },


  ];
  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };
  const handlePageChange = (page, limit) => {

    setCurrentLimit(limit);
    var data = bodyData;
    data['page'] = page;
    data['limit'] = limit;
    //getOrders(data, data);
    setBodyData(data);
    setSelected({ keys: [], records: [] });
  };

  return (
    <>
      <Modal okButtonProps={{ style: { display: 'none' } }} visible={isModalVisible} onCancel={togglepopUp} width={1000} style={{ borderRadius: "20px !important", top: 20 }} >

        {/* <div className="popup-box">

          <div className="box"> */}
        <div style={{ borderRadius: "20px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ marginLeft: 30 }}>
                <p style={{ fontWeight: 700, size: 24 }}>{setValue.dst_warehouse} LOAD LIST</p>

                <p style={{ color: '#07A197' }}>{setValue.status}</p>
                <p>Total Cases Picked : {props.data.picked_qty}</p>
                <p>picking Duration : {props.data.picking_duration}</p>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ marginLeft: 220 }}>
                <p style={{ fontSize: 12, }}>Order Id : {setValue.truck} </p>
                <p style={{ fontSize: 12, }}>PTRF Id : {setValue.id} </p>
                <p style={{ fontSize: 12, }}>Staging Area : {setValue.staging_area} </p>
                <p style={{ fontSize: 12, }}>Date Uploaded : {_date} </p>

              </div>
            </Col>
            <Divider />
          </Row>

          {!isFetching &&
            <div style={{ paddingLeft: '3%', paddingRight: '3%', marginTop: 34 }}>

              <NewTable
                rowKey="truck"
                rowClassName={() => 'editable-row'}
                columns={columns}
                dataSource={trfRecords}
                loading={isFetching}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: currentLimit,
                  defaultCurrent: 1,
                  total: total,
                  onChange: handlePageChange,
                }}
                size="small"
                scroll={{ x: '1600px' }}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: selected.keys,
                  onChange: handleSelectRecord,
                }}
              />

            </div>}
          {isFetching && <div style={{
            flex: 1, width: "100%",
            height: 500 + "px",
            backgroundColor: "#f0f2f5",
            textAlign: 'center',
            justifyContent: 'center',
            verticalAlign: 'center',
            padding: 150 + "px"
          }}><Spin></Spin></div>}

          {/* </div>
        </div> */}
          <Button type="primary" style={{ marginLeft: '3%', padding: '5px 24px', height: '35px', marginTop: '2%', backgroundColor: '#ffffff' }} disabled={isSubmitDisabled}
            onClick={() => { onSubmit() }}>Update Details</Button>
          {/* <button onClick={()=> {onSubmit()}}>submit</button> */}
        </div>
      </Modal>

    </>
  );
};

export default EditLoadList;