import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Button, Dropdown, Checkbox, Space, Form, Menu, AutoComplete, Spin, message } from 'antd';
import NewTable from '../../../molecules/NewTable/NewTable';
import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates2';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import { DatePicker } from 'antd';
import { SearchOutlined, CaretDownFilled, DeleteFilled, FilePdfOutlined, CarryOutFilled, EyeFilled, MailFilled, DownOutlined, EditFilled } from '@ant-design/icons';
import { Input } from 'antd';
import errorHandler from '../../../../utils/errorHandler';
import Popup from './TrfPopup';
import { EditStatus } from './EditStatus';
import { LoadingList } from './loadingList';
import { ConfirmCancel } from './ConfirmCancel';
import { MarkLoaded } from './MarkLoaded';
import TruckLoadingForm from '../TruckLoadList/TruckLoadingForm';

import { MarkDispatched } from './MarkDispatched';
import EditLoadList from './EditLoadList';

function PDFViewer({ pdfUrl }) {
  return (
    <div>
      <h2>PDF Viewer</h2>
      <iframe
        src={pdfUrl}
        width="100%"
        height="600px"
        style={{ border: 'none' }}
        title="PDF Viewer"
      />
    </div>
  );
}

function ManageOrders(props) {

  const [pdfUrl, setPdfUrl] = useState('');
  const [openForm, setOpenForm] = useState(true);

  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);

  const [storeRecord, setStoreRecord] = useState([]);
  const [isMenuInput, isMenuInputSelected] = useState(false);
  const [editLoadList, setEditLoadListPopup] = useState(false);
  const [menuInputValue, setMenuInputValue] = useState();
  const [confirmCancel, setconfirmCancel] = useState(false);
  const [markLoaded, setmarkLoaded] = useState(false);
  const [markDispatched, setmarkDispatched] = useState(false);
  const [conifrmData, setconfirmData] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [isSelected, setIsSelected] = useState();
  const [isWareHouseSelected, setWareHouseSelected] = useState();
  const [shipments, setOrders] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [trfRecords, setTrfRecords] = useState([]);
  const [form] = Form.useForm();
  const [isSearched, setIsSearched] = useState(false);
  const [currentDate, setcurrentDate] = useState(moment().add(1, "days"));
  const [previousDate, setpreviousDate] = useState(moment().subtract(200, "days"));
  const [isDisabled, setDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopUp, setPopUp] = useState(false);
  const [isStatusPopup, setStatusPopUp] = useState(false);
  const [isLoadingListPopup, setLoadingListPopup] = useState(false);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [selectedValue, setSelectedValue] = useState("Filter");
  const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 10, 'start_date': previousDate, 'end_date': currentDate });
  const [_bodyData, _setBodyData] = useState({ 'page': 1, 'limit': 10, 'start_date': previousDate, 'end_date': currentDate, 'search_text': "", 'warehouse_text': "", 'status_text': "" });
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [dateString, setdateString] = useState("Date");
  const [value, setValue] = useState();
  const [storeData, setstoreData] = useState([]);
  const [targetValue, settargetValue] = useState("empty");
  const [_isvisible, setisVisible] = useState(false);
  const [getmails, setgetmails] = useState([]);
  const [getStatus, setStatusList] = useState([]);
  const [result, setResult] = useState([]);

  const addToLoadingQueue = (record) => {
    console.log(record)
    var apiUrl = `${API_URI}/admin/v1/loading_queue`;
    setFetching(true);
    var data = record;
    let authorization = localStorage.getItem("jwtToken");
    setBodyData(data);
    axios.post(apiUrl, data, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {
        if ("data" in response && "data" in response.data) {
            if (("data" in response && response.data.data["loading_status"] == "DISPATCHED") && ("data" in response && response.data.message == "Already Dispatched")) {
              sendNotification('The status of this truck has been changed to dispatched already. Please refresh your page. ', '', 'error');
          }
          console.log(response,"data from loadingqueue")
          setFetching(false);
          getOrders();
        }
      })
      .catch(async (error) => {
        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns after generating trfs', '', 'error');
      })
  }

  const onChange = (e) => {
    console.log(e);
    if (e.target.checked) {
      !isSelected && setIsSelected(e.target.name);
      console.log(e.target.name);
    } else {

      setIsSelected(null);
      console.log(isSelected);
    }
  };
  const OnWareHouseChange = (e) => {
    console.log(e);
    if (e.target.checked) {
      !isWareHouseSelected && setWareHouseSelected(e.target.name);
      console.log(e.target.name)
    } else {

      setWareHouseSelected(null);
      console.log(isWareHouseSelected);
    }
  };

  const DateText = (startDate, endDate) => {
    var temp1 = startDate._d;
    var temp2 = endDate._d;
    console.log(temp1, temp2);
    temp1 = temp1.toString();
    temp2 = temp2.toString();
    var values1 = temp1.split(" ");
    var values2 = temp2.split(" ");
    var month1 = values1[1];
    var month2 = values2[1];
    var date1 = values1[2];
    var date2 = values2[2];
    var year1 = values1[3];
    var year2 = values2[3];
    console.log(month1, month2, date1, date2, year1, year2);
    if (month1 == month2) {
      var datestr = month1 + " " + date1 + "-" + date2 + "," + year1;
      console.log(datestr);
      return datestr;
    }
    else if (month1 != month2 && year1 != year2) {
      var datestr = month1 + " " + date1 + " " + year1 + "-" + month2 + " " + date2 + "" + year2;
      console.log(datestr);
      return datestr;
    }
    else if (month1 != month2) {
      var datestr = month1 + " " + date1 + "-" + month2 + " " + date2 + "," + year1;
      console.log(datestr);
      return datestr;
    }
  }

  const onOpenChange = open => {
    if (open) {
      console.log(dates);
    } else {
      setHackValue(undefined);
    }
  };
  var dataa;
  var x;

  const togglePopup = () => {
    setPopUp(!isPopUp);
  }
  const toggleLoadlistPopup = () => {
    setEditLoadListPopup(!editLoadList)
  }
  const toggleconfirmPopup = () => {
    setconfirmCancel(!confirmCancel);
  }
  const toggleStatusPopup = () => {
    setStatusPopUp(!isStatusPopup);
  }
  const toggleLoadingListPopup = () => {
    setLoadingListPopup(!isLoadingListPopup);
  }

  const updateStatus = async (props) => {
    let data = '',
      apiUrl = `${API_URI}/admin/v1/truck`;
    setFetching(true);

    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);
    console.log(selectedValue);
    let _store = {
      'truck': props.truck,
      'status': 'CANCELLED'
    };
    await axios.put(apiUrl, _store, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {
        console.log(response);
        if ("data" in response && "data" in response.data) {
          sendNotification('Status Updated', '', 'success');
          setTimeout(function () {
            window.location.reload(1);
          }, 1000);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })

  }


  const getStatusList = () => {

    console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/admin/v1/status`;
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.data);
          //setStatusList(response.data.data.data);
          var _store = response.data.data.data;
          for (let i = 0; i < _store.length; i++) {
            console.log(_store[i].status);
            getStatus.push(_store[i].status);
          }
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Mail Lists', '', 'error');
      });

  }
  const getWareHouseList = () => {

    console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/admin/v1/warehouses`;
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.data);
          //setStatusList(response.data.data.data);
          var _store = response.data.data.data;
          for (let i = 0; i < _store.length; i++) {
            console.log(_store[i].warehouse_name);
            warehouseList.push(_store[i].warehouse_name);
          }

        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Mail Lists', '', 'error');
      });

  }

  const getMailList = () => {
    console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/admin/v1/loading_list_mails`;
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          //console.log(response.data.data);
          setgetmails(response.data.data);
          var _store = response.data.data;
          console.log(_store);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Mail Lists', '', 'error');
      });
  }

  const setTrucks = (filterData = bodyData) => {
    setcurrentDate((moment().format("YYYY-MM-DD")));
    console.log(currentDate);
    var prevDate = filterData.start_date.format("YYYY-MM-DD");
    var endDate = filterData.end_date.format("YYYY-MM-DD");
    console.log(prevDate);
    setpreviousDate(prevDate);
    var getDate = DateText(filterData.start_date, filterData.end_date);
    console.log(getDate);
    setdateString(getDate);
    console.log(filterData);
    console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/admin/v1/trucks?start_date=${prevDate}&end_date=${endDate}&page=${filterData.page}&limit=${filterData.limit}`;

    setFetching(true);
    console.log(filterData);
    let authorization = localStorage.getItem("jwtToken");

    setBodyData(filterData);
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setOrders(response.data.data.data);
          // setstoreData(...response.data.data.data.dst_warehouse);
          setFetching(false);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      });
  }


  const getOrders = useCallback((filterData = bodyData) => {
    setTrucks(filterData);
  }, []);

  const getSearchedItems = useCallback((filterData = bodyData) => {
    handleSearch(filterData);
  }, []);

  useEffect(() => {

  }, [getSearchedItems]);

  useEffect(() => {
    //handleSearch();
  }, [getSearchedItems])


  useEffect(() => {
  }, [getOrders]);


  useEffect(() => {
    setTrucks();
    getMailList();
    getStatusList();
    getWareHouseList();
  }, [getOrders]);

  const handlePageChange = (page, limit) => {

    setCurrentLimit(limit);
    var data = bodyData;
    var _data = _bodyData;
    data['page'] = page;
    data['limit'] = limit;
    _data['page'] = page;
    _data['limit'] = limit;
    setBodyData(data);
    if (isSearched == true) {
      // _data['search_text']  = searchValue;
      // _data['warehouse_text'] = selectedValue;
      // _data['status_text']  = selectedValue;
      _data['search_text'] = searchValue;
      _data['warehouse_text'] = isWareHouseSelected;
      _data['status_text'] = isSelected;
      getSearchedItems(data, data);
    }
    else {
      getOrders(data, data);
    }

    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };


  const columns = [
    {
      title: 'OrderId',
      dataIndex: 'truck',
      key: 'truck',
      width: '80px',
      align: 'left',
      onFilter: (value, record) => {
        return record.truck.toLowerCase().includes(value.toLowerCase());
      }
    },
    {
      title: 'Warehouse',
      dataIndex: 'dst_warehouse',
      key: 'dst_warehouse',
      width: '100px',
    },
    {
      title: 'Picking Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      render: (text, record) => {
        if (record.status == 'CANCELLED') {
          return (
            <span style={{ color: 'red' }}>{text}</span>
          );
        }
        else {
          return text;
        }
      },
    },

    {
      title: 'Loading Status',
      dataIndex: 'loading_status',
      key: 'status',
      width: '100px',
      render: (text, record) => {
        if (record.status == 'CANCELLED') {
          return (
            <span style={{ color: 'red' }}>{text}</span>
          );
        }
        else {
          return text;
        }
      },
    },

    {
      title: 'PTRF ID',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
    }, {
      title: 'Staging',
      dataIndex: 'staging_area',
      key: 'staging_area',
      width: '100px',
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '100px',
      render: (text, record) => {
        var store = record.created_at;
        var temp = store.split(" ");
        let date = temp[1];
        let month = temp[2];
        let year = temp[3];
        var dateString = month + " " + date + "," + year;
        return <span>{dateString}</span>
      }
    },
    {
      title: '',
      key: 'operation',

      width: '50px',
      render: (text, record) => {

        return (
          // <span style={{color:'var(--primary-color)'}}>Manage Order <CaretDownFilled /> </span>
          // <Button onClick={()=>{}} type="text" danger style={{color:'#07A197', borderColor:'#07A197'}}>Manage Order <CaretDownFilled /></Button>
          <Dropdown overlay={ManageOrderMenu(record)} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space>
                <span style={{ fontWeight: 500 }}>Manage order</span>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    }

  ];

  function DownloadPDFButton(e) {
    const handleDownloadPDF = () => {
      let authorization = localStorage.getItem("jwtToken");
      const apiUrl = `${API_URI}/admin/v1/pallet_item?truck=${e.truck}`;

      axios.get(apiUrl, {
        headers: {
          'Authorization': authorization,
          'Content-Type': 'application/pdf; charset=utf-8'
        },
        responseType: 'blob' // Important: Specify responseType as 'blob'
      })
        .then(response => {
          console.log('Response:', response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // setPdfUrl(url);
          console.log(url)
          // Use response.data to get the blob
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'sample.pdf'); // Name the PDF file
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link); // Clean up
        })
        .catch(error => {
          console.error('Error downloading the PDF:', error);
        });
    };

    handleDownloadPDF();
  }

  const handleSearch = (filterData = _bodyData) => {
    setIsSearched(true);
    setisVisible(false);
    console.log(isSelected);
    console.log(isWareHouseSelected);
    console.log(bodyData.limit);
    console.log(selectedValue);
    console.log(searchValue);
    var flag_status = false;
    let store = ["ON_HOLD", "ON_QUEUE", "CANCELLED", "FOR_LOADING"];
    for (let i = 0; i < store.length; i++) {
      console.log(searchValue);
      if (store[i].includes(searchValue)) {
        flag_status = true;
        console.log('before');
      }
    }
    console.log(targetValue);
    console.log(_bodyData);
    console.log("heyeyy");
    let apiUrl = "";
    if (_bodyData.warehouse_text == "" && _bodyData.search_text == "" && _bodyData.status_text == "") {
      if ((searchValue == null && isSelected == null && isWareHouseSelected == null) || (searchValue == undefined && isSelected == undefined && isWareHouseSelected == undefined)) {
        console.log("test");
        message.warning("Please Provide some Input to Search");
        return;
      }
      apiUrl = `${API_URI}/admin/v1/trucks?page=${bodyData.page}&limit=${bodyData.limit}&truck=${searchValue}`;
      if (isSelected != null) {
        apiUrl = `${API_URI}/admin/v1/trucks?page=${bodyData.page}&limit=${bodyData.limit}&status=${isSelected}`;

      }
      if (isWareHouseSelected != null) {
        apiUrl = `${API_URI}/admin/v1/trucks?page=${bodyData.page}&limit=${bodyData.limit}&dst_warehouse=${isWareHouseSelected}`;

      }
      if (isWareHouseSelected != null && isSelected != null) {
        apiUrl = `${API_URI}/admin/v1/trucks?page=${bodyData.page}&limit=${bodyData.limit}&status=${isSelected}&dst_warehouse=${isWareHouseSelected}`;

      }
    }
    else {
      apiUrl = `${API_URI}/admin/v1/trucks?page=${_bodyData.page}&limit=${_bodyData.limit}&truck=${_bodyData.search_text}`;
      if (_bodyData.status_text != null) {
        apiUrl = `${API_URI}/admin/v1/trucks?page=${_bodyData.page}&limit=${_bodyData.limit}&status=${_bodyData.status_text}`;
      }
      if (_bodyData.warehouse_text != null) {
        var __store = _bodyData.warehouse_text;
        let _value = __store.substr(4, __store.length);
        console.log(_value);
        apiUrl = `${API_URI}/admin/v1/trucks?page=${_bodyData.page}&limit=${_bodyData.limit}&dst_warehouse=${_value}`;
      }
    }

    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setOrders(response.data.data.data);
          setFetching(false);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }
  function _disabledDate(current) {
    let start = '2018-01-01';
    let endDate = (moment().add(1, "days"));
    if (current > endDate) {
      return true;
    }
    else {
      return false;
    }
  }

  function _handlemenu(e) {
    console.log(e.key)
  }

  function handlesubmitmenu(e) {
    setisVisible(true);
    var store = e.key;
    console.log(e.key);
    setSelectedValue(e.key);
    console.log(selectedValue);
    if (e.key.includes("dst")) {
      setSelectedValue(e.key);
      console.log(e.key);
    }
    else {
      if (e.key == 'On Hold') {
        setSelectedValue('ON_HOLD');
      }
      if (e.key == 'On Queue') {
        setSelectedValue('ON_QUEUE');
      }
      if (e.key == 'For Loading') {
        setSelectedValue('FOR_LOADING');
      }
      if (e.key == 'Cancelled') {
        setSelectedValue('CANCELLED');
      }
      if (e.key == 'Pick In Progress') {
        setSelectedValue('PICK_IN_PROGRESS');
      }
      if (e.key == 'Loading In Progress') {
        setSelectedValue('LOADING_IN_PROGRESS');
      }
      if (e.key == 'Loaded') {
        setSelectedValue('LOADED');
      }
    }


  }
  const getInputValue = (event) => {
    const userValue = event.target.value;
    setSearchValue(userValue);
    console.log(userValue);
  };
  const getMenuText = (event) => {
    const temp = event.target.value;
    isMenuInputSelected(true);
    setMenuInputValue(temp);
  }
  const setValuee = (value) => {
    if (value != null) {
      setValue(value);
      console.log(value);
      var store1 = value[0];
      var store2 = value[1];
      var data = [];
      data["limit"] = currentLimit;
      data["page"] = currentPage;
      data["start_date"] = store1;
      data["end_date"] = store2;
      setBodyData(data);
      getOrders(data);

    }

  }
  const closeModal = (values) => {
    setOpenForm(false);
  }
  const fetchDeliveryNote = async (e) => {
    debugger;
    let authorization = localStorage.getItem("jwtToken");
    const apiUrl = `${API_URI}/admin/v1/delivery_note?truck=${e.truck}`;

    axios.get(apiUrl, {
      headers: {
        'Authorization': authorization,
        'Content-Type': 'application/pdf; charset=utf-8'
      },
      responseType: 'blob' // Important: Specify responseType as 'blob'
    })
      .then(response => {
        debugger;
        console.log('Response:', response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // setPdfUrl(url);
        console.log(url)
        // Use response.data to get the blob
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'delivery_note.pdf'); // Name the PDF file
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Clean up
      })
      .catch(error => {
        debugger;
        sendNotification("Error downloading the PDF");

        console.error('Error downloading the PDF:', error);
      });
  };
  const ManageOrderMenu = (e) => {
    var flag = false;
    if (e.status == 'ON_HOLD' || e.status == 'ON_QUEUE' || e.loading_status == 'FOR_LOADING' || e.status == 'PICKER_COMPLETED') {
      flag = true;
    }
    console.log(e);
    const ViewPickingListMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {
      setStoreRecord(e);
      setTrfRecords(e);
      setPopUp(true);
    }}>
      <EyeFilled />
      <Space></Space> View Picking List
    </Menu.Item>;
    const generateDeliveryNoteMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {
      fetchDeliveryNote(e)
    }}>
      <FilePdfOutlined />
      <Space></Space> Generate Delivery Note
    </Menu.Item>;

    const SendLoadListMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {

      console.log(e);

      setTimeout(() => {
        setTrfRecords(e);
        setLoadingListPopup(true);
      }, 500)
    }}> <MailFilled />
      <Space></Space>
      Mark as Loaded
    </Menu.Item>;

    const CancelOrderMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {

      setconfirmData(e)
      setconfirmCancel(true)
    }}><DeleteFilled style={{ color: 'red' }} />
      <Space></Space> Cancel Order
    </Menu.Item>;

    const MarkDispatchedMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {

      setTimeout(() => {

        setconfirmData(e)
        setmarkDispatched(true)
      }, 500)
    }}> <MailFilled /> <Space></Space> Mark Dispatched</Menu.Item>

    const ViewPalletMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {
      DownloadPDFButton(e)
    }}><EyeFilled />
      <Space></Space>
      View Pallet Item
    </Menu.Item>;

    const EditLoadlistMenuItem = <Menu.Item style={{ marginLeft: '10px' }} onClick={() => {
      setStoreRecord(e);
      setTrfRecords(e);
      setEditLoadListPopup(true);
    }}><EditFilled style={{ color: 'orange' }} />
      <Space></Space>
      Edit Load List
    </Menu.Item>;

    if (e.status == 'CANCELLED') {
      return (
        <Menu style={{ width: '15em', maxHeight: '500px' }} >
          {ViewPickingListMenuItem}
        </Menu>
      );
    }
    else if (e.loading_status == "LOADED") {
      return (
        <Menu style={{ width: '15em', maxHeight: '500px' }}>
          {ViewPickingListMenuItem}
          {/* {SendLoadListMenuItem} */}
          {MarkDispatchedMenuItem}
        </Menu>
      );
    }
    else if (e.loading_status == "DISPATCHED" || e.loading_status == "NOTIFIED_INV") {
      return (
        <Menu style={{ width: '15em', maxHeight: '500px' }}>
          {generateDeliveryNoteMenuItem}
        </Menu>
      );
    }

    else if (e.status == "PICKER_COMPLETED") {

      if (e.loading_status == "CHECKED") {
        return (
          <Menu style={{ width: '15em', maxHeight: '500px' }}>
            {ViewPickingListMenuItem}
            {ViewPalletMenuItem}
            {SendLoadListMenuItem}
            {flag && <> {CancelOrderMenuItem}
            </>
            }
            {flag && <> {EditLoadlistMenuItem}
            </>
            }
          </Menu>

        );
      }
      else {
        return (
          <Menu style={{ width: '15em', maxHeight: '500px' }}>
            {ViewPickingListMenuItem}
            {/* {SendLoadListMenuItem} */}
            {flag && <> {CancelOrderMenuItem}</>
            }
          </Menu>

        );
      }
    }

    else {
      return (
        <Menu style={{ width: '15em', maxHeight: '500px' }}>
          {ViewPickingListMenuItem}
          {/* {SendLoadListMenuItem} */}
          {flag && <> {CancelOrderMenuItem}
          </>
          }
        </Menu>
      );
    }
  }
  async function sendMail(truck) {
    let data = '',
      apiUrl = `${API_URI}/admin/v1/loading_list?truck=${truck}`;
    setFetching(true);

    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);
    var postData = {};
    await axios.post(apiUrl, postData, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {

          sendNotification('Mail sent successfully', '', 'success');
          setTimeout(function () {
            window.location.reload(1);
          }, 1000);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }
  const addTruckData = async (values) => {
    console.log(values);
    setOpenForm(false);
    debugger
    ;
    setmarkLoaded(true);
    // values['truck'] = e['truck'];
    await addToLoadingQueue(values);
    await sendMail(values['truck']);


  }
  const userMenu = (
    <Menu style={{ scrollbarWidth: 'thin', maxHeight: '500px', overflow: 'auto' }} onClick={handlesubmitmenu}>
      <div style={{ marginTop: '10%' }}>
        <span style={{ marginLeft: '10%', fontWeight: 600, fontSize: 14 }}>Status</span>
      </div>
      {
        getStatus.map((item) => (
          <Menu.Item style={{ marginLeft: '10%' }} key={item}> <Checkbox disabled={isSelected ? isSelected !== item : false} name={item} key={item} onChange={onChange}>
            {item}
          </Checkbox></Menu.Item>


        ))}

      <span style={{ marginLeft: '10%', marginTop: '20%', fontWeight: 600, fontSize: 14 }}>WareHouse</span>
      {
        warehouseList.map((item) => (
          <Menu.Item style={{ marginLeft: '10%' }} key={item}> <Checkbox disabled={isWareHouseSelected ? isWareHouseSelected !== item : false} name={item} key={item} onChange={OnWareHouseChange}>
            {item}
          </Checkbox></Menu.Item>

        ))}
      <Button type="primary" onClick={() => { handleSearch() }} style={{ marginTop: '5%', textAlign: 'center', marginLeft: '3%', borderColor: 'var(--primary-color)', width: '90%', marginLeft: 10 }}>Filter</Button>

    </Menu>
  );
  return (

    <Admin sidenav={props.nav}>
      <PageHeader
        title=""
        extra={[
        ]}
      >
        <div style={{ marginTop: 10, left: '20.83%', right: '20.83%', paddingRight: '3%' }}>
          <RangePicker value={hackValue || value}
            onCalendarChange={val => setDates(val)}
            onChange={val => setValuee(val)}
            style={{ marginTop: 0, floodColor: '#07A197' }}
            disabledDate={val => _disabledDate(val)}
            onOpenChange={onOpenChange} />
          <p style={{ position: 'absolute', marginLeft: 15, lineHeight: '24px', fontWeight: 600, fontSize: '12px', display: 'inline-block', marginTop: 4, color: '#07A197' }}> {dateString}</p>
          <div style={{ marginTop: '3%' }}></div>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Input onChange={getInputValue} id="searchBox" style={{ padding: '8 16', height: 48, border: '2px solid #D9DBE9', borderRadius: 12, width: '70%', gap: 16, background: '#ffffff', }} placeholder="Search by Truck" prefix={<SearchOutlined />} />
            <div style={{ padding: '8px 12px 8px 16px', height: 48, border: '2px solid #D9DBE9', borderRadius: 12, width: 212, gap: 16, background: '#ffffff', }}>
              <Dropdown onClick={() => { setisVisible(!_isvisible) }} visible={_isvisible} overlay={userMenu} trigger={['click']}>
                <a style={{ color: 'rgba(0, 0, 0, 0.85)' }} onClick={e => { e.preventDefault() }}>

                  <DownOutlined style={{ left: 30 }} />
                </a>
              </Dropdown>
            </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}>
            <Button ghost style={{ color: 'var(--primary-color)', borderColor: 'var(--primary-color)', padding: '5px 20px', width: 90, height: '35px', marginTop: '2%', backgroundColor: '#ffffff' }} onClick={handleSearch}>Search</Button>
            <Button type="primary" style={{ marginLeft: '3%', padding: '5px 24px', width: 90, height: '35px', marginTop: '2%', backgroundColor: '#ffffff' }} onClick={() => { window.location.reload() }}>Reset</Button>
          </div>
        </div>
      </PageHeader>
      {!isFetching &&
        <div style={{ paddingLeft: '3%', paddingRight: '3%', marginTop: 34 }}>
          <NewTable
            rowKey="truck"
            rowClassName={"editable-row"}
            columns={columns}
            dataSource={shipments}
            loading={isFetching}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: currentLimit,
              defaultCurrent: 1,
              total: total,
              onChange: handlePageChange,
            }}
            size="small"
            scroll={{ x: '1600px' }}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selected.keys,
              onChange: handleSelectRecord,
            }}
          />
        </div>}
      {isFetching && <div style={{
        flex: 1, width: "100%",
        height: 500 + "px",
        backgroundColor: "#f0f2f5",
        textAlign: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        padding: 150 + "px"
      }}><Spin></Spin></div>}
      {isPopUp &&
        <>
          <Popup data={trfRecords} isModalVisible={isPopUp} handleClose={togglePopup} />
        </>
      }
      {isStatusPopup && <> <EditStatus data={trfRecords} handleClose={toggleStatusPopup} />
        <span className="close-iconn" onClick={toggleStatusPopup}>x</span>
      </>}
      {isLoadingListPopup && <>

        {/* TODO: */}
        {openForm && <TruckLoadingForm open={openForm} setOpenForm={setOpenForm} truckRec={trfRecords} onComplete={addTruckData} closeModal={closeModal}></TruckLoadingForm>}



      </>}
      {markLoaded &&
        <>
          {/* <LoadingList data={getmails} truck={trfRecords.truck} handleClose={() => setmarkLoaded(false)} />
          <span className="close-iconn" onClick={() => setmarkLoaded(false)} >x</span> */}
        </>
      }

      {confirmCancel &&
        <>
          <ConfirmCancel data={conifrmData} isModalVisible={isPopUp} handleClose={toggleconfirmPopup} />
        </>
      }{
        // markLoaded && <>
        //   <MarkLoaded data={conifrmData} isModalVisible={isPopUp} handleClose={toggleconfirmPopup}>

        //   </MarkLoaded>
        // </>
      }{
        markDispatched && <>
          <MarkDispatched data={conifrmData} isModalVisible={isPopUp} handleClose={toggleconfirmPopup}>

          </MarkDispatched>
        </>
      }
      {editLoadList &&
        <>
          <EditLoadList data={trfRecords} isModalVisible={isPopUp} handleClose={toggleLoadlistPopup} />
        </>
      }
      {/* {pdfUrl ? <PDFViewer pdfUrl={pdfUrl} /> : 'Loading PDF...'} */}
    </Admin>
  );
}

export default ManageOrders;

