import React, { useState } from 'react';
import { Col, Row, Button, Dropdown, Form, Menu } from 'antd';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import { DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import errorHandler from '../../../../utils/errorHandler';

export function EditStatus(props) {
  const [isFetching, setFetching] = useState(false);
  const [shipments, setOrders] = useState([]);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  var date = new Date();
  const updateStatus = async () => {
    let data = '',
      apiUrl = `${API_URI}/admin/v1/truck`;
    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    let _store = {
      'truck': props.data.truck,
      'status': selectedValue
    };
    await axios.put(apiUrl, _store, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {
        console.log(response);
        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);

          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setOrders(response.data.data.data);
          setFetching(false);
          sendNotification('Status Updated', '', 'success');
          setTimeout(function () {
            window.location.reload(1);
          }, 1000);
        }
      })
      .catch(async (error) => {
        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }
  const [selectedValue, setSelectedValue] = useState(props.data.status);
  function handlesubmitmenu(e) {
    setSelectedValue(e.key);
    var _store = (e.key);
  }
  const togglePopup = props.handleClose;
  const userMenu = (
    <Menu onClick={handlesubmitmenu}>
      {props.data.status == "ON_HOLD" && <Menu.Item key="CANCELLED">Cancelled</Menu.Item>}
      {props.data.status == "ON_QUEUE" && <>
        <Menu.Item key="ON_HOLD">On Hold</Menu.Item>
        <Menu.Item key="CANCELLED">Cancelled</Menu.Item>
      </>
      }
      {props.data.status == "FOR_LOADING" && <>
        <Menu.Item key="LOADING_IN_PROGRESS">Loading in Progress</Menu.Item>
        <Menu.Item key="CANCELLED">Cancelled</Menu.Item>
      </>
      }
      {props.data.status == "LOADING_IN_PROGRESS" && <>
        <Menu.Item key="LOADED">Loaded</Menu.Item>

      </>
      }
      {props.data.status == "LOADED" && <>
      </>
      }
      {props.data.status == "CANCELLED" && <>
      </>
      }
      {props.data.status == "PICK_IN_PROGRESS" && <>
      </>
      }
    </Menu>
  );
  return (
    <div className="status-popup-box">

      <div className="status-box">
        <Row gutter={16}>
          <Col span={24}>
            <div>
              <h4>Edit Order Status for : {props.data.truck}</h4>
              <div style={{ padding: '8px 12px 8px 16px', height: 48, border: '2px solid #D9DBE9', borderRadius: 12, width: 312, gap: 16, background: '#ffffff', }}>
                <Dropdown overlay={userMenu} trigger={['click']}>
                  <a style={{ color: '#06B6AA' }} onClick={e => e.preventDefault()}>
                    {selectedValue}
                    <DownOutlined style={{ left: 30 }} />
                  </a>
                </Dropdown>
              </div>

            </div>
          </Col>
        </Row>
        <div style={{ marginTop: 30, marginLeft: 110 }}>
          <Button ghost style={{ color: '#07A197', borderColor: '#07A197', display: 'inline-block' }} onClick={togglePopup}>Cancel</Button>
          {console.log(props.data)}
          <Button type="primary" style={{ marginLeft: 4 }} onClick={updateStatus}> Update Status</Button>

        </div>
      </div>

    </div>
  );
}
