import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Col, Row, Button, Form, Divider, Popconfirm, Spin } from 'antd';

import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates2';
import { EditableTable, SimpleForm, DragSortingTable } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';

import errorHandler from '../../../../utils/errorHandler';
import { DatePicker, Radio, Space } from 'antd';
import Picklist from '../common/Picklist';



function QueueList({ queueName = "Manage Queue" }) {
    console.log(queueName)
    //debugger;

    const [selected, setSelected] = useState({ keys: [], records: [] });
    const [isFetching, setFetching] = useState(false);
    const [trucks, setTrucks] = useState([]);
    const [_date, setDate] = useState("Date Here");
    const [unqueueTrucks, setUnqueueTrucks] = useState([]);
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit, setCurrentLimit] = useState(100);
    const [total, setTotal] = useState(0);
    var date = new Date();
    const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100 });
    const [lastUpdate, setQueueLastUpdate] = useState('');
    const [openTrf, setOpenTrf] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState('');
    ;
     const setOnHoldTrucks = (filterData = bodyData) => {
        console.log("heyeyy");
        let data = '',
            apiUrl = `${API_URI}/admin/v1/loading_queue`;
        // debugger;
        setFetching(true);
        console.log(filterData);
        let authorization = localStorage.getItem("jwtToken");

        setBodyData(filterData);
        console.log(apiUrl);

        axios.get(apiUrl, { params: filterData, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
            .then((response) => {

                console.log(response);

                if ("data" in response && "data" in response.data) {
                    console.log(response.data.data.page);
                    ;
                    setCurrentPage(response.data.data.page);
                    setTotal(response.data.data.total_count);
                    setTrucks(response.data.data.data);
                    // setOrders(response.data.data.data);
                    // setOrders(response.data.data.data);
                    ;
                    var updatedAt = moment(response.data.data.queue_last_updated).format('MMMM DD, YYYY, HH:mm'); // June 1, 2019

                    setQueueLastUpdate(updatedAt);
                    setFetching(false);
                }
            })
            .catch(async (error) => {

                await errorHandler(error);
                setFetching(false);
                sendNotification('Failed to fetch Txns', '', 'error');
            })
    }

    const getOrders = useCallback((filterData = bodyData) => {
      // debugger;
        setOnHoldTrucks(filterData);
    }, []);

    useEffect(() => {
        //debugger;
        console.log('hello')
        setOnHoldTrucks();
    }, []);


    // useEffect(() => {
    //     setOnHoldTrucks();
    // }, [getOrders]);
    const handlePageChange = (page, limit) => {

        setCurrentLimit(limit);
        var data = bodyData;
        data['page'] = page;
        data['limit'] = limit;
        getOrders(data, data);
        setBodyData(data);
        setSelected({ keys: [], records: [] });
    };


    const handleSelectRecord = (selectedRowKeys, selectedRows) => {
        setSelected({ keys: selectedRowKeys, records: selectedRows });
    };


    const columns = [


        {
            title: 'Order ID',
            dataIndex: 'truck',
            key: 'truck',
            width: '100px',
        },
        {
            title: 'Warehouse',
            dataIndex: 'dst_warehouse',
            key: 'dst_warehouse',
            width: '100px',
        },
        {
            title: 'PTRF ID',
            dataIndex: 'id',
            key: 'id',
            width: '100px',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend'],
        },
        {
            title: 'Date Uploaded',
            dataIndex: 'loading_date',
            key: 'loading_date',
            width: '100px',
            render:(text,record) => {
                var dateString = [];
                var _store = record.loading_date;
                _store = _store.split(" ");
                dateString.push(_store[1]);
                dateString.push(_store[2]);
                dateString.push(_store[3]);
                var _datestring = dateString[0] + " " + dateString[1] + " " + dateString[2];
                console.log(_datestring);
                setDate(_datestring);
                return (
                    <span>{_date}</span>
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '100px',
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: '120px',
            render: (text, record) => {
                if (record.status != "REMOVED") {
                    // borderColor: 'var(--primary-color )'
                    // style={{ color: 'var(--primary-color)',  }}
                    return <div style={{ textAlign: "end" }}> <a onClick={() => {
                        viewPickList(record);
                    }}
                    >View PickList</a><br></br>


                        <Popconfirm
                            title="Are you sure to remove this truck from queue?"
                            onConfirm={() => { removeFromQueue(record) }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a style={{ color: 'red' }} href="#">Remove x</a>
                        </Popconfirm>
                        {/* <Button style={{ color: 'red' }} type="text" onClick={() => {
                            removeFromQueue(record);
                        }}
                        >Delete</Button> */}
                    </div>
                }
            },
        },
        // {
        //     title: '',
        //     key: 'operation',
        //     fixed: 'right',
        //     width: '100px',
        //     render: (text, record) => {
        //         if (record.status != 'REMOVED') {
        //             return (

        //                 <Popconfirm
        //                     title="Are you sure to remove this truck from queue?"
        //                     onConfirm={() => { removeFromQueue(record) }}
        //                     // onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a style={{ color: 'red' }} href="#">Remove x</a>
        //                 </Popconfirm>

        //             );
        //         }
        //     }
        // },


    ];


    function viewPickList(record) {

        setSelectedTruck(record);
        setOpenTrf(!openTrf);
    }
    const inputs = [
        {
            name: 'date',
            label: 'Date',
            type: 'datepicker',
            rules:
                [
                    {
                        required: false,
                        message: 'Scheduled End is required'
                    }
                ],
            hasFeedback: true,
            col: true,
            defaultValue: moment(),
        },

    ];
    const removeFromQueue = (record) => {
        const result = trucks.filter(word => word.id != record.id);
        setTrucks(trucks => result);
        record['status'] = "REMOVED";
        setUnqueueTrucks(unqueueTrucks => [...unqueueTrucks, record])
        debugger;
    }

    function handleQueueSave(data, defaultRank) {
        console.log(data);
        debugger;
        const apiUrl = `${API_URI}/admin/v1/loading_queue`;
        let authorization = localStorage.getItem("jwtToken");
        if (unqueueTrucks.length > 0) {
            confirmRemoveTruck(unqueueTrucks);
        }
        axios.put(apiUrl, { "data": data, "default_rank": defaultRank }, { headers: { 'authorization': authorization } }
            , { withCredentials: false })
            .then((response) => {

                window.location.reload();
            })
            .catch((error) => {
                alert("Error occured ");
            });
        

    }
    function confirmRemoveTruck(data) {
        ;
        const apiUrl = `${API_URI}/admin/v1/remove_from_loading_queue`;
        let authorization = localStorage.getItem("jwtToken");
        axios.post(apiUrl, { "data": data }, { headers: { 'authorization': authorization } }
            , { withCredentials: false })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                alert("Error occured ");
            });
    }

    const togglePopup = () => {
        setOpenTrf(!openTrf);
    }
        ;
    console.log("end of queuelist");
    return (
        <div>
            {
                !isFetching &&

                <div>
                    <div>

                        <p style={{fontWeight:600}}>{queueName}</p>

                        <p>Drag card to rearrange order by priority</p>
                        <i> Last Update At {lastUpdate}</i>

                    </div>

                    <DragSortingTable onComplete={handleQueueSave} columns2={columns} dataSource={trucks} rankParam="loading_rank" reqRemovedTrucks={[...unqueueTrucks]} ></DragSortingTable>

                </div>
            }
            {
                isFetching && <div style={{
                    flex: 1, width: "100%",
                    height: 500 + "px",
                    backgroundColor: "#f0f2f5",
                    textAlign: 'center',
                    justifyContent: 'center',
                    verticalAlign: 'center',
                    padding: 150 + "px"
                }}><Spin></Spin></div>
            }
            {openTrf && <>

                {/* <Popup data={selectedTruck} handleClose={togglePopup} isModalVisible={openTrf} /> */}
                <Picklist data={selectedTruck} handleClose={togglePopup} isModalVisible={openTrf}></Picklist>
                {/* <span className="close-icon" onClick={togglePopup}>x</span> */}

            </>
            }
        </div>


    );
}

export default QueueList;
