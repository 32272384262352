import React, { useState, useEffect } from 'react';
import { Button, Form, Divider, Modal, Select, Typography } from 'antd';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import errorHandler from '../../../../utils/errorHandler';
import { Input, Space } from 'antd';
import { PlusOutlined, } from '@ant-design/icons';
import sendNotification from '../../../../utils/sendNotification';
const { Option } = Select;

function TruckLoadingForm({ open, setOpenForm, truckRec, onComplete, closeModal }) {
    const [fetching, setFetching] = useState(true);
    const [loadingBay, setLoadingBay] = useState([]);
    const [driverName, setDriverName] = useState([]);
    const [carrier, setCarrier] = useState([]);


    const [sealNumbers, setSealNumbers] = useState([]);
    const [_isvisible, setisVisible] = useState(false);
    const [_truckType, _setTruckType] = useState([]);
    const [_truckValue, setTruckValue] = useState([]);
    const [_platenumber, _setplatenumber] = useState([]);
    const [addPlate, setAddPlate] = useState([]);
    const [_loadingBay, _setLoadingBay] = useState([]);
    const [_trucker, _setTrucker] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [truckerList, setTruckerList] = useState([]);
    const [plateNumberList, setPlateNumberList] = useState([]);
    const [truckTypeList, setTruckTypeList] = useState([]);
    const [storeResponse, setStoreResponse] = useState([]);
    const truckTypes = ["6W", "10W", "FWD"]
        ;
    var resp;
    var _flag = false;

    const getTruckDetails = () => {
        let data = '',
            apiUrl = `${API_URI}/admin/v1/trucker_info`;
        let authorization = localStorage.getItem("jwtToken");
        axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
            .then((response) => {
                if ("data" in response && "data" in response.data) {
                    resp = response.data.data.data;
                    setStoreResponse(resp);
                    var localTruckList = [];
                    var localPlateList = [];
                    for (var i = 0; i < resp.length; i++) {
                        if (!localTruckList.includes(resp[i].company_name)) {
                            localTruckList.push(resp[i].company_name);
                        }
                        ;
                        localPlateList.push(resp[i].plate_number)
                    }
                    console.log(localTruckList);
                    setCompanyName(localTruckList)
                    _setplatenumber(localPlateList);

                    var arr = [];
                    for (var i = 0; i < localTruckList.length; i++) {

                        var warehouseId = {
                            'id': localTruckList[i],
                            "value": localTruckList[i],
                            "label": localTruckList[i]
                        }
                        arr.push(warehouseId);
                    }
                    var arr2 = [];
                    for (var i = 0; i < localPlateList.length; i++) {
                        var warehouseId = {
                            'id': localPlateList[i],
                            "value": localPlateList[i],
                            "label": localPlateList[i]
                        }
                        arr2.push(warehouseId);
                    }
                    var arr3 = [];
                    for (var i = 1; i < 11; i++) {
                        var warehouseId = {
                            'id': "LoadingBay" + i,
                            "value": "LoadingBay" + i,
                            "label": "LoadingBay" + i
                        }
                        arr3.push("loadingBay" + i);
                    }
                    var arr4 = [];
                    for (var i = 0; i < truckTypes.length; i++) {
                        var trucks = {
                            'id': truckTypes[i],
                            "value": truckTypes[i],
                            "label": truckTypes[i]
                        }
                        arr4.push(trucks);
                    }
                    setTruckerList(arr);
                    setPlateNumberList(arr2);
                    setLoadingBay(arr3);
                    setTruckTypeList(arr4);
                    setFetching(false);
                }
            })
            .catch(async (error) => {
                ;
                await errorHandler(error);
                sendNotification('Failed to fetch Txns', '', 'error');
            })
    }

    useEffect(() => {
        getTruckDetails();
    }, []);
    const [form] = Form.useForm();

    const onFormFinish = async () => {
        const { meta, ...values } = await form.validateFields();
        var addValues = {
            "loading_bay": _loadingBay,
            "trucker": _trucker,
            "plate_number": addPlate,
            "truck_type": _truckValue,
            "driver_name": driverName,
            "carrier": carrier,
            "seal_number": sealNumbers.join(","),
            "truck": truckRec['truck'],
            "loading_status": "LOADED"

        };
        onComplete(addValues);

    };

    const togglePopup = () => {
        setOpenForm(!open);
        closeModal(false);
    }
    const addInputField = () => {
        setSealNumbers([...sealNumbers, '']);
    };
    const handleInputChange = (index, value) => {
        const updatedSealNumbers = [...sealNumbers];
        updatedSealNumbers[index] = value;
        setSealNumbers(updatedSealNumbers);
    };
    const removeSealNumber = (index) => {
        const updatedSealNumbers = sealNumbers.filter((_, i) => i !== index);
        setSealNumbers(updatedSealNumbers);
    };
    const _handlePlateChange = (e) => {
        setAddPlate(e);
        var temp = [];
        console.log(storeResponse);
        for (var i = 0; i < storeResponse.length; i++) {
            if (storeResponse[i].plate_number == e) {
                temp.push(storeResponse[i].company_name);
            }
        }
        if (temp.length > 0) {
            setCompanyName(temp);
        }
    }

    const _handleTruckerChanege = (e) => {
        _setTrucker(e);
        var temp = [];
        for (var i = 0; i < storeResponse.length; i++) {
            if (storeResponse[i].company_name == e) {
                temp.push(storeResponse[i].plate_number);
            }
        }
        if (temp.length > 0) {
            _setplatenumber(temp);
        }

    }

    return (
        !fetching &&
        <Modal okButtonProps={{ style: { display: 'none' } }} onCancel={togglePopup} handleClose=
            {togglePopup} visible={open} width={500} style={{ borderRadius: "20px !important", top: 20, }} >
            {truckRec['truck']}
            <div style={{ borderRadius: "20px" }}>
                <div style={{ marginTop: 20 }}>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Loading Bay</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={_setLoadingBay}
                        placeholder=""
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {loadingBay.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Trucker</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={_handleTruckerChanege}
                        placeholder=""
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                </Space>
                            </>
                        )}
                    >
                        {companyName.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Plate Number</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={_handlePlateChange}
                        placeholder=""
                        visible={_isvisible}
                        value={addPlate}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                    <Input placeholder="Please enter item" onChange={e => setAddPlate(e.target.value)} />
                                    <Typography.Link
                                        onClick={(e) => { e.preventDefault(); _flag = true; }}
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </Typography.Link>
                                </Space>
                            </>
                        )}
                    >
                        {_platenumber.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Truck Type</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={setTruckValue}
                        placeholder=""
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {truckTypes.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Driver's Name</p>

                    <Input onChange={e => { setDriverName(e.target.value) }} id="searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Driver Name" />
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Carrier</p>

                    <Input onChange={e => { setCarrier(e.target.value) }} id="searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Carrier" />

                    {/* <p style={{ fontSize: '12px', marginTop: '10px' }}>Seal Numbers</p>
                    <Input onChange={e => { setSealNumbers(e.target.value) }} id="seal-searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Seal Numbers" /> */}
                    <div>
                        <p style={{ fontSize: '12px', marginTop: '10px' }}>Seal Numbers</p>
                        {sealNumbers.map((sealNumber, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}
                            >
                                <Input
                                    value={sealNumber}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    id={`seal-input-${index}`}
                                    style={{
                                        padding: '8 16',
                                        height: 38,
                                        border: '1px solid #D9DBE9',
                                        borderRadius: 12,
                                        flexGrow: 1,
                                        background: '#ffffff',
                                    }}
                                    placeholder={`Seal Number ${index + 1}`}
                                />
                                <Button
                                    onClick={() => removeSealNumber(index)}
                                    type="text"
                                    style={{
                                        marginLeft: '8px',
                                        color: '#FF4D4F', // Optional: Red color for a delete action
                                    }}
                                >
                                    X
                                </Button>
                            </div>
                        ))}
                        <Button
                            onClick={addInputField}
                            type="dashed"
                            style={{ marginTop: '10px' }}
                        >
                            + Add Seal Number
                        </Button>
                    </div>

                </div>

                <div style={{ padding: '20px 20px' }}>
                </div>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={onFormFinish}
                    style={{ borderColor: 'var(--primary-color)', padding: '5px 20px', width: '100%', alignItems: 'center', marginTop: '5%', backgroundColor: '#ffffff' }}
                >
                    Save Loading Details
                </Button>
            </div>
        </Modal>);
}
export default TruckLoadingForm;